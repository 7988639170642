<template>
    <div style="padding: 20px;">
        <el-tabs v-model="activeTabName">
            <el-tab-pane label="邮件设置" name="email">
                <email-setting></email-setting>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import EmailSetting from './EmailSetting.vue'

export default {
    data(){
        return {
            activeTabName: 'email',
        }
    },
    components: {
        EmailSetting
    }
}
</script>