<template>
    <div>
        <el-form ref="form" :model="form" :rules="formRules" class="email-form" label-width="150px" :status-icon="true">
            <el-form-item label="电子邮箱服务">
                <el-select v-model="form.emailServerKey" placeholder="请选择" style="width: 100%">
                <el-option
                    key="0"
                    label="系统默认邮箱服务"
                    value="default">
                </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="SMTP服务地址" prop="host">
                <el-input v-model="form.host"></el-input>
            </el-form-item>
            <el-form-item label="SMTP服务端口">
                <el-input-number v-model="form.port"></el-input-number>
            </el-form-item>
            <el-form-item label="用户名">
                <el-input v-model="form.username" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码">
                <el-input v-model="form.password" type="password" autocomplete="new-password"></el-input>
            </el-form-item>
            <el-form-item label="测试收件箱">
                <el-input v-model="form.testmail">
                <el-button slot="append">发送</el-button>
                </el-input>
            </el-form-item>
            <el-form-item >
                <el-button type="primary" :loading="loading">保存配置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    data(){
        return {
            form: {
                emailServerKey: '',
                host: "",
                port: 25,
                username: "",
                password: "",
                testmail: ''
            },
            formRules: {
                host: {
                    required: true, message: '邮件服务器地址不能为空', trigger: 'blur'
                },
                username: {
                    required: true, message: '用户名不能为空', trigger: 'blur'
                },
                password: {
                    required: true, message: '密码不能为空', trigger: 'blur'
                }
            },
            loading: false
        }
    }
}
</script>